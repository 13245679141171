<template>
  <div class="container-fluid mt-3">
    <div class="help-container mb-1">
      <el-drawer
        title="I am the title"
        :visible.sync="drawer"
        :with-header="false"
      >
        <h4 class="mt-5 mb-3">Visual Guide:</h4>
        <hr />
        <h4>Quick Guide:</h4>
        <ul>
          <li>Follow BookMeInScotty on: <a href="#">Twitter</a>.</li>
          <li>
            Read and subscribe to our newsletter
            <a href="#">Book Me In Scotty Blog</a>.
          </li>
          <li>Follow Book Me In Scotty on <a href="#">Instagram</a>.</li>
          <li>
            Follow Me In Scotty on
            <a href="https://www.facebook.com/creativetim">Facebook</a>.
          </li>
        </ul>
        <hr />

        <h4>Help Center:</h4>
        <a href="#">Read the official documentation here</a>
      </el-drawer>
    </div>
    <!--  Main Page -->
    <div>
        <card type="frame" class="row justify-content-center">
          <div class="card-body mb--3">
            <div class="col-md-12 mr--1 ml--1">

              <el-select
                v-model="selectedEventIds"
                filterable
                multiple
                :placeholder="
                  $t(
                    'listAttendees.selectAtLeastOneEventToDisplayYourGuestsList'
                  )
                "
                class="dropdown-search"
                @change="eventSelected(selectedEventIds)"
              >
                <el-option
                  v-for="item in events"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.name }}</span>
                  <span
                    style="
                      float: right;
                      color: var(--el-text-color-secondary);
                      font-size: 13px;
                    "
                    ><small class="text-muted mr-2">
                      {{ item.start_at | humanFullTime }}</small
                    >
                    <badge rounded :type="happeningTime(item).label">
                      <span class="status">{{ happeningTime(item).date }}</span>
                    </badge></span
                  >
                </el-option>
              </el-select>
            </div>

            <div v-if="selectedEventId" class="col-md-12">
              <el-tabs class="mt-2" v-model="selectedEventId">
                <el-tab-pane
                  :name="item.id"
                  v-for="item in selectedEvents"
                  :key="item.id"
                >
                  <span slot="label">
                    <i class="fa fa-calendar-day mr-2" /> {{ item.name }}
                  </span>
                </el-tab-pane></el-tabs
              >
            </div>
          </div>
        </card>

      <div class="row">
        <div class="col-xl-4 col-md-4">
          <base-stats-card
            :title="$t('listAttendees.totalAttendees')"
            type="gradient-red"
            :sub-title="stats.totalGuests"
            :loading="loading"
            icon="fa fa-users"
          >
            <template slot="footer">
              <span class="text-danger mr-2"><i class="fa fa-users" /></span>
              <span class="text-nowrap">{{
                $t("listAttendees.totalNoOfGuests")
              }}</span>
            </template>
          </base-stats-card>
        </div>

        <div class="col-xl-4 col-md-4">
          <base-stats-card
            :title="$t('listAttendees.totalRevenue')"
            type="gradient-info"
            :sub-title="stats.totalSales"
            :loading="loading"
            icon="ni ni-money-coins"
          >
            <template slot="footer">
              <span class="text-info mr-2"><i class="fa fa-coins"></i></span>
              <span class="text-nowrap">{{
                $t("listAttendees.totalGrossSales")
              }}</span>
            </template>
          </base-stats-card>
        </div>

        <div class="col-xl-4 col-md-4">
          <base-stats-card
            :title="$t('listAttendees.totalBookings')"
            type="gradient-base"
            :sub-title="stats.totalBookings"
            :loading="loading"
            icon="ni ni-chart-pie-35"
          >
            <template slot="footer">
              <span class="text-base mr-2"
                ><i class="fa fa-ticket-alt"></i
              ></span>
              <span class="text-nowrap">{{
                $t("listAttendees.totalNoOfReservations")
              }}</span>
            </template>
          </base-stats-card>
        </div>
      </div>

      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-6">
              <h3 class="mb-0">
                {{ $t("listAttendees.myGuestsList") }}
                <!--                <i class="fa fa-info-circle pointer-class" @click="drawer = true"/>-->
              </h3>
              <small class="text-muted">
                {{ $t("general.showing") }} {{ to }} {{ $t("general.of") }}
                {{ total }} r{{ $t("general.results") }}

                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{ selectedRows.length }} rows selected
                </span>
              </small>
            </div>
            <div class="col-6 text-right button-dropdown">
              <el-dropdown
                trigger="click"
                class="dropdown btn base-button btn-base btn-icon btn-icon btn-fab btn-primary btn-sm"
                :disabled="attendees.length === 0"
              >
                <span class="btn-inner--icon">
                  <span class="mr-1 ml-2 text-white">{{
                    $t("listAttendees.download")
                  }}</span>
                  <i class="fa fa-download text-white mt-0" />
                </span>

                <el-dropdown-menu
                  class="dropdown-menu dropdown-menu-arrow show text-center"
                  slot="dropdown"
                >
                  <div style="display: flex; flex-direction: column">
                    <base-button
                      type="primary"
                      icon
                      size="sm"
                      class="btn-base btn-icon"
                      @click="dataExport('csv')"
                    >
                      <span class="btn-inner--icon"
                        ><i class="fa fa-download"></i
                      ></span>
                      <span class="btn-inner--text">{{
                        $t("listAttendees.asCsvFile")
                      }}</span>
                    </base-button>
                    <base-button
                      type="primary"
                      icon
                      size="sm"
                      class="btn-base btn-icon"
                      @click="dataExport('excel')"
                    >
                      <span class="btn-inner--icon"
                        ><i class="fa fa-download"></i
                      ></span>
                      <span class="btn-inner--text">{{
                        $t("listAttendees.asExcelFile")
                      }}</span>
                    </base-button>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </template>
        <div>
          <div
            v-if="firstRQDone"
            class="d-flex justify-content-center flex-wrap"
          >
            <div class="col-10">
              <base-input
                v-model="query"
                type="search"
                prepend-icon="fas fa-search"
                :placeholder="
                  $t(
                    'listAttendees.searchWithBookingNoNameOrEmailToFilterForMoreSpecificResults'
                  )
                "
                clearable
              />
            </div>
          </div>
          <el-empty
            image="/img/placeholders/attendees-default.png"
            :description="
              $t('listAttendees.pleaseSelectAnEventToListYourGuests')
            "
            v-if="!firstRQDone"
          />

          <el-table
            class="table-responsive align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="attendees"
            v-if="firstRQDone"
            @sort-change="sortChange"
          >
            <div slot="empty" v-if="loading">
              <img src="/img/loading.gif" style="height: 100px; width: 100px" />
            </div>

            <el-table-column
              type="expand"
              style="padding-left: 0; padding-right: 0"
            >
              <template v-slot="{ row }">
                <div class="col-md-12">
                  <!-- Special or Dietary requirement -->
                  <el-descriptions direction="vertical" border>
                    <el-descriptions-item v-if="row.special_requirements">
                      <template slot="label">
                        <i class="el-icon-tickets"></i>
                        {{ $t("listAttendees.specialOrDietaryRequirement") }}
                      </template>
                        <strong> {{ row.special_requirements || '-'}}</strong>
                    </el-descriptions-item>


                    <el-descriptions-item>
                      <template slot="label">
                        <i class="ni ni-mobile-button"></i>
                        {{ $t('listAttendees.phoneNumber') }}:
                      </template>
                      <strong> {{ row.phone_number || '-' }} </strong>
                    </el-descriptions-item>
                  </el-descriptions>

                  <!-- Other data fields -->
                  <el-descriptions class="mt-2" border>
                    <el-descriptions-item
                      :label="$t('listAttendees.ticketName') + ':'"
                    >
                      {{ row.ticket_name }}
                    </el-descriptions-item>

                    <el-descriptions-item
                      :label="$t('listAttendees.bookedQuantity') + ':'"
                    >
                      {{ row.quantity }}
                    </el-descriptions-item>

                    <el-descriptions-item
                        :label="$t('listAttendees.paymentAt') + ':'"
                    >
                      {{ row.created_at }}
                    </el-descriptions-item>


                    <el-descriptions-item
                        :label="$t('listAttendees.finalPrice') + ':'"
                    >
                      {{ row.final_price || '-' }}
                    </el-descriptions-item>

                    <el-descriptions-item
                        :label="$t('listAttendees.discountedPrice') + ':'"
                    >
                      {{ getTicketDiscount(row) }}
                    </el-descriptions-item>

                    <el-descriptions-item
                        :label="$t('listAttendees.paymentConfirmed') + ':'"
                    >
                      {{ row.updated_at }}
                    </el-descriptions-item>

                  </el-descriptions>

                  <!-- Only for Admins -->
                  <el-descriptions v-if="isSuperUser()" class="mt-2" border>
                    <el-descriptions-item label="Attendee ID:">
                      {{ row.id }}
                    </el-descriptions-item>

                    <el-descriptions-item label="Ticket ID:">
                      {{ row.ticket_id }}
                    </el-descriptions-item>

                    <el-descriptions-item label="Event ID:">
                      {{ row.event_id }}
                    </el-descriptions-item>

                    <el-descriptions-item label="Transaction ID:">
                      {{ row?.metadata?.transaction_id }}
                    </el-descriptions-item>

                    <el-descriptions-item label="SnipCart ID:">
                      {{ row?.metadata?.snipcart_id }}
                    </el-descriptions-item>

                    <el-descriptions-item label="Total Amount Paid:">
                      {{ getTotalBookingAmount(row) }}
                    </el-descriptions-item>

                    <el-descriptions-item label="Transaction Fee:">
                      {{ getTransactionsFeeAmount(row) }}
                    </el-descriptions-item>

                    <el-descriptions-item label="Booked At:">
                      {{ row.created_at }}
                    </el-descriptions-item>
                  </el-descriptions>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('listAttendees.bookingNo') + '.'"
              prop="ticket_number"
              min-width="200px"
              sortable
            >
              <template v-slot="{ row }">
                <strong>{{ row.ticket_number }}</strong>
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('listAttendees.attendee')"
              prop="name"
              min-width="160px"
              sortable
            />

            <el-table-column
              label="Email"
              prop="email"
              min-width="200px"
              sortable
            />

            <!--            <el-table-column-->
            <!--              label="Qty"-->
            <!--              prop="quantity"-->
            <!--              min-width="117px"-->
            <!--              sortable-->
            <!--            />-->

            <el-table-column
              :label="$t('listAttendees.bookings')"
              prop="price"
              min-width="150px"
              align="center"
              sortable
            >
              <template v-slot="{ row }">
                <span class="text-center">
                  {{ row.quantity }} x
                  <i class="fas fa-ticket-alt mr-1" /><strong class="ml-1"
                    >|
                  </strong>
                  A$ {{ getTotalBookingAmount(row) }}
                </span>
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('listAttendees.payment')"
              min-width="150px"
            >
              <template v-slot="{ row }">
                <badge class="badge-dot mr-4" type="">
                  <i
                    :class="`bg-${
                      row.is_paid === 1
                        ? 'success'
                        : row.is_paid === -1
                          ? 'warning'
                          : 'danger'
                    }`"
                  ></i>
                  <span class="status">{{
                    row.is_paid === 1
                      ? "Completed  "
                      : row.is_paid === -1
                        ? "Pending"
                        : "Cancelled"
                  }}</span>
                </badge>
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('listAttendees.bookedAt')"
              min-width="200px"
              prop="created_at"
              sortable
            >
              <template v-slot="{ row }">
                {{ row.created_at | humanFullTime }}
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('listAttendees.actions')"
              min-width="115px"
              align="left"
            >
              <template v-slot="{ row }">
                <div class="table-actions pl-2">
                  <el-tooltip
                    content="Remove Attendee"
                    placement="top"
                  >
                    <a
                      type="text"
                      @click="deleteAttendee(row.id)"
                      class="table-action"
                      data-toggle="tooltip"
                      style="cursor: pointer"
                    >
                      <i class="far fa-trash-alt text-danger"></i>
                    </a>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
            <!--
            <el-table-column :label="$t('listAttendees.actions')" class-name="actions"
                            min-width="120px"
                            align="center">
              <div slot-scope="{ row }" class="table-actions">
                <el-tooltip :content="$t('general.edit')" placement="top">
                  <a
                    type="text"
                    @click="editTicket(row)"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="far fa-edit"></i>
                  </a>
                </el-tooltip>

                <el-tooltip :content="$t('general.delete')" placement="top">
                  <a
                    type="text"
                    @click="deleteTicket(row.id)"
                    class="table-action table-action-delete"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="far fa-trash-alt"></i>
                  </a>
                </el-tooltip>
              </div>
            </el-table-column> -->
          </el-table>
        </div>
        <div
          v-if="firstRQDone"
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <el-select
            class="select-primary pagination-select"
            v-model="pagination.perPage"
            placeholder="Per page"
            :disabled="attendees.length === 0"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <base-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          />
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { exportCsv } from "@/util/export-csv";
import { exportExcel } from "@/util/export-excel";

import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
  Empty,
  Drawer,
  Tabs,
  TabPane,
  Descriptions,
  DescriptionsItem,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import moment from "moment";
import QrCodeScanner from "@/components/QrCodeScanner.vue";

export default {
  layout: "DashboardLayout",

  components: {
    QrCodeScanner,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Empty.name]: Empty,
    [Drawer.name]: Drawer,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Descriptions.name]: Descriptions,
    [DescriptionsItem.name]: DescriptionsItem,
  },

  data() {
    return {
      drawer: false,
      attendees: [],
      events: [],
      selectedRows: [],
      query: null,
      sort: "-created_at",
      selectedEventId: null,
      selectedEventIds: [],
      selectedEvents: [],
      firstRQDone: false,
      total: 0,
      stats: {
        totalBookings: "0",
        totalSales: "0",
        totalGuests: "0",
      },
      allAttendees: [],
      loading: true,
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [25, 50, 100],
      },
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
  },
  computed: {
    user() {
      return this.$store.getters["profile/me"];
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },
  watch: {
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    selectedEventId: {
      handler: "getListDebounced",
      immediate: false,
      deep: true,
    },
    attendees: {
      handler: "updateStats",
      immediate: false,
      deep: true,
    },
    query: {
      handler: "getListDebounced",
    },
  },
  mounted() {
    this.fetchEvents();
  },
  created() {
    if (this.$route.query.item) {
      this.selectedEventId = this.$route.query.item;
      this.selectedEventIds.push(this.$route.query.item);
    }
  },
  beforeDestroy() {
    // Check if the current route already has query parameters
    const hasQueryParameters = Object.keys(this.$route.query).length > 0;

    // If there are query parameters, navigate to the same route with null query
    if (hasQueryParameters) {
      this.$router.replace({ query: null });
    }
  },
  methods: {
    getListDebounced: _.debounce(function () {
      if (this.selectedEventId) {
        this.loading = true;
        this.getList();
      }
    }, 300),

    async getList() {
      let params = {
        ...(this.sort ? { sort: this.sort } : {}),
        filter: {
          ...(this.selectedEventId ? { item_id: this.selectedEventId } : {}),
          ...(this.selectedEventId && this.query
            ? { ticket_number: this.query }
            : {}),
          ...(this.selectedEventId && this.query ? { name: this.query } : {}),
          ...(this.selectedEventId && this.query ? { email: this.query } : {}),
        },
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
        //  include: "items",
      };

      await this.$store.dispatch("attendees/list", params).then(() => {
        this.attendees = this.$store.getters["attendees/list"];
        this.total = this.$store.getters["attendees/listTotal"];
        this.firstRQDone = true;
      });

      this.loading = false;
    },
    searchData(dataTable, query) {
      return this.attendees.filter(
        (data) =>
          !search ||
          `data.${query}.toLowerCase().includes(search.toLowerCase())`
      );
    },
    eventSelected(eventIds) {
      this.selectedEvents = this.events
        .filter((event) => eventIds.includes(event.id))
        .map(({ id, name }) => ({ id, name }));
      if (eventIds?.length > 0) {
        this.selectedEventId = eventIds[eventIds?.length - 1];
      } else {
        this.selectedEventId = null;
        this.firstRQDone = false;
        this.stats = {
          totalBookings: "0",
          totalSales: "0",
          totalGuests: "0",
        };
        this.total = 0;
        this.attendees = [];
      }
    },
    async deleteAttendee(id) {
      const confirmation = await swal.fire({
        title: this.$t("listAttendees.archiveThisAttendeeFromTheQuestList"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("listAttendees.yesDeleteIt") + "!",
        cancelButtonText: this.$t("listAttendees.noKeepIt"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("attendees/destroy", id).then(
            () => this.getList() // fetch to refresh the remaining Item list
          );

          this.$notify({
            type: "success",
            message: this.$t("listAttendees.attendeeRemovedSuccessfully") + ".",
          });
        }
      } catch (error) {
        if (error.response.data.errors[0]) {
          this.$notify({
            type: "danger",
            message:
              this.$t("listAttendees.thisAttendeeStillHasAssociatedEvents") +
              ".",
          });
        } else {
          this.$notify({
            type: "danger",
            message:
              this.$t(
                "listAttendees.oopsSomethingWentWrongContactOurSupportTeam"
              ) + "!",
          });
        }
      }
    },
    async fetchEvents() {
      try {
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            // ...(this.start_at && this.end_at
            //   ? { start_at: [this.start_at, this.end_at] }
            //   : { start_at: [] }),
            // ...(this.end_at ? { start_at: [this.end_at] } : { start_at: [] }),
            ...(this.user ? { user_id: this.user.id } : { user_id: 0 }),
            // ToDo to be refactored
            // ...(this.query ? { name: this.query } : {}),
            // ...(this.query ? { category: this.query } : {}),
            // ...(this.query ? { tags: this.query } : {}),
          },
          // page: {
          //   number: this.pagination.currentPage,
          //   size: this.pagination.perPage,
          // },
          //   include: "category,tags",
        };
        // fire a GET request to load the items
        await this.$store.dispatch("items/list", params).then(() => {
          this.items = this.$store.getters["items/list"];

          this.events = [];
          this.items.forEach((event) => {
            this.events.push({
              name: event.name,
              id: event.id,
              start_at: event.start_at,
            });
          });
        });

        this.loading = false;
      } catch (error) {
        this.loading = false;

        // // Notification
        // this.$notify({
        //   type: "danger",
        //   message: "Oops, something went wrong with your account!",
        // });
        // this.setApiValidation(error.response.data.errors);
      }
    },
    async updateStats() {
      if (this.selectedEventId) {
        this.loading = true;

        let params = {
          filter: {
            ...(this.selectedEventId ? { item_id: this.selectedEventId } : {}),
          },
        };
        await this.$store.dispatch("attendees/list", params).then(() => {
          this.allAttendees = this.$store.getters["attendees/list"];
        });

        // compute stats
        let sumBookings = 0;
        let sumGuests = 0;
        let sumSales = 0;
        this.allAttendees.forEach((attendee) => {
          sumBookings++;

          sumGuests += attendee.quantity;
          sumSales += attendee.price * attendee.quantity;
        });

        this.stats.totalBookings = sumBookings.toString();
        this.stats.totalGuests = sumGuests.toString();
        this.stats.totalSales = "A$ " + sumSales.toString();

        this.loading = false;
      }
    },
    async dataExport(fileType) {
      try {
        let params = {
          filter: {
            ...(this.selectedEventId ? { item_id: this.selectedEventId } : {}),
            ...(this.selectedEventId && this.query
              ? { ticket_number: this.query }
              : {}),
            ...(this.selectedEventId && this.query ? { name: this.query } : {}),
            ...(this.selectedEventId && this.query
              ? { email: this.query }
              : {}),
          },
        };

        await this.$store.dispatch("attendees/list", params).then(() => {
          this.allAttendees = this.$store.getters["attendees/list"];
        });

        const exportData = [];
        this.allAttendees.forEach((attendee) => {
          exportData.push({
            [this.$t("listAttendees.ticketBookingNo")]: attendee.ticket_number,
            [this.$t("listAttendees.ticketsName")]: attendee.ticket_name,
            [this.$t("listAttendees.fullName")]: attendee.name,
            'EMAIL' : attendee.email,
            'PHONE NUMBER' : attendee.phone_number ?? '-',
            [this.$t("listAttendees.specialOrDietaryRequirements")]:
              attendee.special_requirements,
            [this.$t("listAttendees.quantity")]: attendee.quantity,
            [this.$t("listAttendees.excelPricePerTicket")]: attendee?.final_price * attendee?.quantity,
            [this.$t('listAttendees.excelFinalPaidPrice')]: attendee?.final_price,
            [this.$t("listAttendees.paid")]: attendee.price === 0 && attendee?.final_price === 0
                ? 'RSVP'
                :  this.$t("listAttendees.yes"),
            [this.$t('listTickets.discount')]: attendee?.has_discount ? (attendee?.price * attendee?.quantity) - (attendee?.final_price * attendee?.quantity) : this.$t('listTickets.no'),
            [this.$t('listTickets.excelPaymentConfirmed')]: attendee?.is_confirmed === 1
                ? this.$t("listTickets.paid")
                : this.$t('listTickets.pending'),
            [this.$t("listAttendees.bookingStatus")]:
              attendee.is_paid === 1 &&  attendee?.is_confirmed === 1
                ? this.$t("listAttendees.completed")
                : this.$t("listAttendees.pending"),
            [this.$t("listAttendees.bookedAT")]: attendee.created_at,
          });
        });
        if (fileType === "csv") {
          exportCsv(exportData, "bookmeinscotty-guest-list.csv");
        } else {
          exportExcel(exportData, "bookmeinscotty-guest-list.xlsx");
        }
        this.loading = false;
      } catch (error) {
        console.log("Error", error);
        this.loading = false;
      }
    },
    addTicket() {
      this.$router.push({ name: "Add Ticket" });
    },
    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
    // editTicket(row) {
    //   this.$router.push({
    //     name: "Edit Ticket",
    //     params: { id: row.id },
    //   });
    // },
    getTotalBookingAmount(booking) {
      return booking?.price * booking?.quantity;
    },
    getTransactionsFeeAmount(data) {
      let ticketFee = (data?.price * 3.99) / 100 + 0.99;
      if (!_.isEmpty(ticketFee)) {
        return ticketFee + 0.99;
      }
      return 0;
    },
    happeningTime(eventDate) {
      // Client-User DateΤimezone
      const currentTimezone = this.user.timezone ?? this.timezone;
      const today = moment().tz(currentTimezone);

      // Event DateTimezone
      const originalDTZ = moment.tz(eventDate.start_at, eventDate.timezone);
      const happening = originalDTZ.clone().tz(currentTimezone);

      return {
        date: happening.fromNow(),
        label: today.diff(happening, "hours") < 0 ? "success" : "warning",
      };
    },
    getTicketDiscount(row) {
      let specialPrice =  row.price - row.final_price;

      return row?.has_discount ? -1 * specialPrice : '-';
    }
  },
};
</script>
<style>
.el-table_1_column_1.undefined.el-table__expand-column.el-table__cell {
  th {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
</style>
